.box_big {
    display: block;
    box-sizing: border-box; /* important so its size for width is not content but the border (so when you place padding, doesnt break)*/
    width: 100%;
    box-shadow: 0px 0px 20px 10px rgba(123, 123, 185, 0.1);
    border-radius: 20px;
    padding: 25px;
    background-color: white;

    /*Ease in*/
    --webkit-animation-name: fadeIn;
    --webkit-animation-duration: 1s;
    animation-name: fadeIn;
    animation-duration: 1s;
  }

  .box_alert {
    color: #444;
    font-size: 14px;
    font-weight: 500;
    display: block;
    box-sizing: border-box; /* important so its size for width is not content but the border (so when you place padding, doesnt break)*/
    border-radius: 6px;
    padding: 10px;
    background-color: rgb(255, 224, 121);
  }

  .box_error {
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: block;
    box-sizing: border-box; /* important so its size for width is not content but the border (so when you place padding, doesnt break)*/
    border-radius: 6px;
    padding: 10px;
    background-color: rgb(255, 141, 121);

    /*Ease in*/
    --webkit-animation-name: fadeIn;
    --webkit-animation-duration: 1s;
    animation-name: fadeIn;
    animation-duration: 1s;
  }
