.Home {
    background-color: rgb(38, 76, 147);
    text-align: center;
    font-family: 'Museo';
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
}  